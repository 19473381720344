/**
 * Cookiebar iFrame
 *
 * @global jQuery
 * @author Daniele De Nobili
 */

(function ($) {
    'use strict';

    window.onCookiePolicyAccepted(function () {
        $('iframe').attr('src', function () {
            return $(this).data('src');
        });
        
        $("#content").fitVids();
    });

}(jQuery));