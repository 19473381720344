/**
 * Pages
 *
 * 01. Form PayPal
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

	/**
	 * Form PayPal
	 *
	 * @global jQuery
	 * @requires plugins/jquery.magnificpopup.js
	 * @author Daniele De Nobili
	 */
    $('.paypal-form').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#your-name',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function() {
                if($(window).width() < 700) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#your-name';
                }
            }
        }
    });

}(jQuery));