var videoGA = (window.videoGA) ? window.videoGA : {};

(function ($) {
    "use strict";

    window.onYouTubeIframeAPIReady = function(){
        videoGA.onReadyYouTube();
    };

    videoGA = {
        iframes: [],
        vimeoCount: 0,
        youtubeCount: 0,
        init: function(){

            videoGA.iframes = $('iframe');

            // warn if GA is not installed or initialized
            if (typeof ga !== "function")
            {
                console.warn('Warning! Universal Analytics (universal.js) not installed!');
                return;
            }

            // generate a unique ID if not specified by the user
            $.each(videoGA.iframes, function(index, iframe){

                // generate an ID if missing
                if(!$(iframe).attr('id')){
                    var id = 'v' + ("0000" + (Math.random()*Math.pow(36,4) << 0).toString(36)).slice(-4);
                    $(iframe).attr('id', id);
                }

                // count how many vimeo videos are in the page
                if(videoGA.isVimeo($(iframe).attr('src')))
                {
                    // recreate src URL
                    if(!videoGA.hasParam(iframe, 'api'))
                    {
                        $(iframe).attr('src', videoGA.addParams(iframe, {
                                'api': '1',
                                'player_id': $(iframe).attr('id')
                            })
                        );
                    }
                    videoGA.vimeoCount++;
                }

                // count how many youtube videos are in the page
                if(videoGA.isYouTube($(iframe).attr('src')))
                {
                    // recreate src URL
                    var origin = window.location.protocol + '%2F%2F' + window.location.hostname + ( window.location.port ? ':' + window.location.port : '' );
                    if(!videoGA.hasParam(iframe, 'enablejsapi'))
                    {
                        $(iframe).attr('src', videoGA.addParams(iframe, {
                                'enablejsapi': '1',
                                'origin': origin
                            })
                        );
                    }
                    videoGA.youtubeCount++;
                }

            });

            // if we have at least 1 YouTube video then load the iFram API library
            if(videoGA.youtubeCount > 0)
            {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var headTag = document.getElementsByTagName('head')[0];
                headTag.appendChild(tag);
            }

            // check if envent listnere exists, otherwise append it
            if (window.addEventListener){
                window.addEventListener('message', videoGA.onMessageReceived, false);
            } else {
                window.attachEvent('onmessage', videoGA.onMessageReceived, false);
            }
        },
        onMessageReceived: function(e){
            if(!videoGA.isJSON(e.data)) return;
            var data = JSON.parse(e.data);
            var el = $('#'+ data.player_id);

            switch (data.event)
            {
                case 'ready':
                    videoGA.onReadyVimeo();
                    break;
                case 'play':
                    videoGA.play(el);
                    break;
                case 'pause':
                    videoGA.paused(el, 0);
                    break;
                case 'playProgress':
                    videoGA.progress(el, data.data.percent * 100);
                    break;
                case 'finish':
                    videoGA.finished(el);
                    break;
            }
        },
        play: function(iframe){
            if(!$(iframe).data('playSent')){
                videoGA.sendEvent('play', iframe);
                $(iframe).data('playSent', true);
            }
        },
        paused: function(iframe, progress){
            if(!$(iframe).data('pausedSent')){
                videoGA.sendEvent('paused', iframe);
                $(iframe).data('pausedSent', true);
            }
        },
        finished: function(iframe){
            if(!$(iframe).data('finishedSent')){
                videoGA.sendEvent('finished', iframe);
                $(iframe).data('finishedSent', true);
            }
        },
        progress: function(iframe, progress){
            if($(iframe).attr('data-progress'))
            {
                var steps = $(iframe).data('progress').toString().replace(/ /g, '').split(',');

                if(typeof steps !== 'undefined' && Array.isArray(steps) && steps.length)
                {
                    for(var i = steps.length; i--;)
                    {
                        if(parseInt(progress) >= parseInt(steps[i]))
                        {
                            videoGA.sendEvent('progress', iframe, steps[i]);
                            steps.splice(i, 1);
                            $(iframe).data('progress', steps.join(','));
                        }
                    }
                }
            }

        },
        onReadyVimeo: function(){
            $.each(videoGA.iframes, function(index, iframe) {
                if(videoGA.isVimeo($(iframe).attr('src'))){ // Vimeo iFrames
                    $(iframe).data('type', 'Vimeo');
                    videoGA.post('addEventListener', 'play', iframe);
                    videoGA.post('addEventListener', 'seek', iframe);
                    videoGA.post('addEventListener', 'pause', iframe);
                    videoGA.post('addEventListener', 'finish', iframe);
                    videoGA.post('addEventListener', 'playProgress', iframe);
                }
            });
        },
        onReadyYouTube: function(){
            $.each(videoGA.iframes, function(index, iframe) {
                if(videoGA.isYouTube($(iframe).attr('src'))) { // YouTube iFrames
                    $(iframe).data('type', 'YouTube');

                    var YTPlayer = new YT.Player(iframe);

                    YTPlayer.addEventListener('onStateChange', function(e){
                        switch(e.data)
                        {
                            case 0:
                                clearInterval(YTPlayer.timer);
                                videoGA.finished(iframe);
                                break;
                            case 1:
                                videoGA.play(iframe);
                                YTPlayer.timer = setInterval(function(){
                                    videoGA.progress(iframe, (YTPlayer.getCurrentTime() * 100 ) / YTPlayer.getDuration());
                                }, 1000);
                                break;
                            case 2:
                                clearInterval(YTPlayer.timer);
                                videoGA.paused(iframe, (YTPlayer.getCurrentTime() * 100 ) / YTPlayer.getDuration());
                                break;
                            case 3:
                                clearInterval(YTPlayer.timer);
                                break;
                        }
                    });

                }
            });
        },
        post: function(action, value, iframe) {
            var data = {
                method: action
            };

            if(value){
                data.value = value;
            }

            var iframeSrc = $(iframe).attr('src').split('?')[0];
            iframe.contentWindow.postMessage(JSON.stringify(data), iframeSrc);
        },
        sendEvent: function(evt, iframe, value) {
            var events = ['play', 'pause', 'finished', 'progress'];
            if($(iframe).attr('data-events')) // check if there are custom events set
            {
                events = $(iframe).data('events').toString().replace(/ /g, '').split(',');
            }

            if(events.indexOf(evt) == -1) return;
            if(evt == 'progress') evt += ' ('+ value +')';


            ga('send', 'event',
                $(iframe).data('type'),
                evt,
                videoGA.getLabel(iframe),
                undefined,
                {
                    'nonInteraction': 1,
                    'page': window.location.href
                }
            );
        },
        getLabel: function(iframe){
            var title = '#', url = $(iframe).attr('src');
            if($(iframe).attr('title')){
                title = $(iframe).attr('title') + ' (#)';
            } else if($(iframe).attr('data-title')) {
                title = $(iframe).data('title') + ' (#)';
            }

            return title.replace('#', url);
        },
        isVimeo: function(str){
            if (typeof str === 'undefined') return false;
            return (str.indexOf('vimeo') != -1) ? true : false;
        },
        isYouTube: function(str){
            if (typeof str === 'undefined') return false;
            return (str.indexOf('youtube') != -1) ? true : false;
        },
        addParams: function(iframe, params){
            var url = $(iframe).attr('src');

            for(var prop in params) {
                url = videoGA.addParam(url, prop, params[prop]);
            }

            return url;
        },
        addParam: function(url, param, value) {
            var val = new RegExp('(\\?|\\&)' + param + '=.*?(?=(&|$))');
            var parts = url.toString().split('#');
                url = parts[0];
            var hash = parts[1];
            var qstring = /\?.+$/;
            var newURL = url;

            if (val.test(url)){
                newURL = url.replace(val, '$1' + param + '=' + value);
            } else if (qstring.test(url)) {
                newURL = url + '&' + param + '=' + value;
            } else {
                newURL = url + '?' + param + '=' + value;
            }

            if (hash){
                newURL += '#' + hash;
            }

            return newURL;
        },
        hasParam: function(iframe, param){
            var url = $(iframe).attr('src');
            return url.indexOf(param) == -1 ? false : true;
        },
        isJSON: function(str){
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    };

    if(typeof window.onFirstUserInteraction === 'function'){
        window.onFirstUserInteraction(videoGA.init);
    } else {
        videoGA.init();
    }

}(jQuery));
