/**
 * Global
 *
 * 01. SVG
 * 02. Social Share
 * 03. Video Replacement
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

	/**
	 * SVG
	 *
	 * @requires libraries/svg4everybody.js
	 * @requires ie/svg4everybody.legacy.js
	 */
	svg4everybody();


    /**
     * Facebook Page Plugin
     */
    if (typeof fb_lang !== 'undefined') {
        window.onCookiePolicyAccepted(function () {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: fb_appId,
                    xfbml: true,
                    version: 'v2.11'
                });
            };
            loadScript('//connect.facebook.net/' + fb_lang + '/sdk.js');
        });
    }


	/**
	 * Social Share
	 *
	 * @global jQuery
	 * @requires plugins/jquery.social.js
	 * @author Daniele De Nobili
	 */
	if (typeof fb_appId !== 'undefined') {
		$('.social').social({
			template: {
				'facebook-share': '<a href="#" class="social__link"><span class="social__icon"><svg title="Facebook"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--facebook"></use></svg></span><span class="social__count">{total}</span></a>',
				'googleplus': '<a href="#" class="social__link"><span class="social__icon"><svg title="Google Plus"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--google-plus"></use></svg></span><span class="social__count">{total}</span></a>',
				'twitter': '<a href="#" class="social__link"><span class="social__icon"><svg title="Twitter"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--twitter"></use><</svg></span><span class="social__count">Tweet</span></a>',
				'pinterest': '<a href="#" class="social__link"><span class="social__icon"><svg title="Pinterest"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--pinterest"></svg></use></span><span class="social__count">Pin</span></a>'
			},
			totalShareSelector: '#social-count',
			lang: fb_lang || 'en_US',
			facebookAppId: fb_appId || '',
			//countStatsUrl: templateUrl + '/share-count/count-stats.php',
			enableTracking: {
				'facebook-share': 10,
				'facebook-comment': 10,
				'twitter': 10,
				'pinterest': 10,
				'googleplus': 10
			}
		});
	}

	
	/**
	 * Video Replacement
	 *
	 * @global jQuery
	 * @requires plugins/jquery.videoga.js
	 * @author Daniele De Nobili
	 */
	var image = $('#js-videoimage'),
		video = $('#js-videoiframe');

	image.on('click', function () {
		var url = '';
		image.hide();

		if (video.data('source') === 'vimeo') {
			url = 'https://player.vimeo.com/video/' + video.data('video-id') + '?autoplay=1&byline=0&badge=0&title=0&loop=1';
		} else {
			url = 'https://www.youtube.com/embed/' + video.data('video-id') + '?showinfo=0&rel=0&autoplay=1&autohide=1&color=white';
		}

		var iframe = $('<iframe data-progress="25,50,75,90" width="1200" height="675" src="' + url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

		iframe.attr('data-title', video.data('title'));

		video
			.html(iframe)
			.show();

		videoGA.init();
	});


	/**
	 * Fluid Video
	 *
	 * @requires plugins/jquery.fitvids.js
	 */
	$(".post__body, .page__body").fitVids();
	
}(jQuery));